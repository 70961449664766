#kc-form-buttons {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-evenly;;
  grid-gap: 16px;
}
#kc-login-submit-button-container, #kc-login-secondary-button-container {
  width: 100%
}

#kc-totp-supported-apps {
  padding: 0;
}

.MuiAlert-icon {
  margin-top: 2px
}

#totp-image-container {
  margin-top: 0px;
}

/* Providers */

#social-lin {
  width: 100%;
}

#kc-login-divider {
  margin-left: 56px;
  margin-right: 56px;
}

.provider-alias-lin {
  display: contents !important;
}

#kc-providers-container {
  margin-top: 0px
}

.MuiFormGroup-root > div[id^="radio-group-"][id$="-container"] {
  padding: 4px 16px;
}